/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import styled from 'styled-components';
import { SYS_SPACE_FIXED_100 } from '@oneaudi/unified-web-common';
import { Text } from '@oneaudi/unified-web-components';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';

const DisclaimersContainer = styled.div`
  padding-bottom: ${SYS_SPACE_FIXED_100};
`;

export interface DisclaimersProps {
  disclaimers?: string[];
}

export const Disclaimers: React.FC<DisclaimersProps> = ({ disclaimers }) => {
  if (!disclaimers || !disclaimers.length) return null;

  return (
    <DisclaimersContainer>
      {disclaimers?.map((disclaimer, i) => (
        <Text key={`disclaimer${i}`} variant={{ type: 'copy', order: '2', style: 'normal' }}>
          {renderTextWithFootnotesReferencesV2(disclaimer)}
        </Text>
      ))}
    </DisclaimersContainer>
  );
};

Disclaimers.displayName = 'Disclaimers';
