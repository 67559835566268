/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import * as React from 'react';
import { useContext } from 'react';
import styled from 'styled-components';

import { SYS_SPACE_RELATIVE_XS } from '@oneaudi/unified-web-common';
import { Context } from '../../Context';
import { Disclaimers } from './Disclaimers';
import { ConsumptionAndEmissions } from './ConsumptionAndEmissions';

const StyledLegalInfo = styled.div`
  padding: ${SYS_SPACE_RELATIVE_XS} 0;
`;

export interface LegalInfoProps {
  consumptionAndEmissions?: string[];
  disclaimers?: string[];
}

export const LegalInfo: React.FC<LegalInfoProps> = ({ consumptionAndEmissions, disclaimers }) => {
  const { localeService, vueFormatterService } = useContext(Context);
  const hasCaE = Boolean(consumptionAndEmissions && consumptionAndEmissions.length > 0);
  const hasDisclaimers = Boolean(disclaimers && disclaimers.length > 0);

  if (!hasCaE && !hasDisclaimers) {
    return null;
  }

  return (
    <StyledLegalInfo>
      {hasCaE && vueFormatterService && localeService && (
        <ConsumptionAndEmissions
          caeIds={consumptionAndEmissions}
          vueFormatterService={vueFormatterService}
          localeService={localeService}
        />
      )}
      {hasDisclaimers && <Disclaimers disclaimers={disclaimers} />}
    </StyledLegalInfo>
  );
};

LegalInfo.displayName = 'LegalInfo';
