/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from 'react';
import styled from 'styled-components';
import {
  SYS_BREAKPOINT_2_XL,
  SYS_BREAKPOINT_LG,
  SYS_SPACE_FIXED_2000,
  SYS_SPACE_FIXED_700,
  SYS_SPACE_RELATIVE_2_XL,
  SYS_SPACE_RELATIVE_LG,
  ThemeProvider,
} from '@oneaudi/unified-web-common';
import { Headline } from '@oneaudi/unified-web-components';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';

import { enableHTMLEntities } from '../../utils/enableHTMLEntities';
import { useTracking } from '../../utils/useTracking';
import { AppContent } from '../../types';
import { LegalInfo } from '../LegalInfo/LegalInfo';
import { InfoRow } from './InfoRow';

const ArticleIntroContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;

  ${(props) => {
    return `
      background-color: ${props.theme.global.color.canvas.default};
      padding: ${SYS_SPACE_RELATIVE_2_XL} var(--page-margin);
      gap: ${SYS_SPACE_RELATIVE_LG};
    `;
  }}
`;

const ArticleContentWrapper = styled.div`
  @media (min-width: ${SYS_BREAKPOINT_LG}) {
    padding: 0 ${SYS_SPACE_FIXED_700};
  }

  @media (min-width: ${SYS_BREAKPOINT_2_XL}) {
    padding: 0 ${SYS_SPACE_FIXED_2000};
  }
`;

export const ArticleIntro: React.FC<AppContent> = (props) => {
  const {
    headline,
    copy,
    articleCredits,
    articleReadingTime,
    articleCreationDate,
    consumptionAndEmissions,
    disclaimers,
    theme,
  } = props;

  const ref = React.useRef(null);
  const tracking = useTracking(props, ref, {
    implementer: 37,
  });

  React.useEffect(() => {
    tracking.sendReadyEvent();
    tracking.registerImpressionTracking();
  }, []);

  return (
    <ThemeProvider theme={{ name: theme === 'Dark' ? 'dark' : 'light' }}>
      <ArticleIntroContainer ref={ref} data-test-id="article-intro">
        <ArticleContentWrapper>
          <Headline tag="h1" variant={{ type: 'headline', order: '1', weight: 'normal' }}>
            {renderTextWithFootnotesReferencesV2(enableHTMLEntities(headline))}
          </Headline>
          <Headline tag="h2" variant={{ type: 'headline', order: '3', weight: 'normal' }}>
            {renderTextWithFootnotesReferencesV2(enableHTMLEntities(copy))}
          </Headline>
          <InfoRow
            articleCredits={articleCredits}
            articleReadingTime={articleReadingTime}
            articleCreationDate={articleCreationDate}
          />
          <LegalInfo consumptionAndEmissions={consumptionAndEmissions} disclaimers={disclaimers} />
        </ArticleContentWrapper>
      </ArticleIntroContainer>
    </ThemeProvider>
  );
};

ArticleIntro.displayName = 'ArticleIntro';
